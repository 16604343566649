var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"text-end"},[_c('button',{staticClass:"btn btn-secondary mb-3",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.goToCreate.apply(null, arguments)}}},[_vm._v("新增分類")])]),_c('div',{staticClass:"my-5"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
        enabled: true,
        mode: 'records',
        perPage: 10,
        perPageDropdownEnabled: true,
        perPageDropdown: [10, 20],
        nextLabel: '下一頁',
        prevLabel: '上一頁',
        rowsPerPageLabel: 'Rows per page',
        allLabel: '全部',
      }},on:{"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'btn')?_c('span',[_c('button',{staticClass:"btn btn-danger btn-sm me-1 mb-1 mb-md-0",staticStyle:{"white-space":"nowrap"},attrs:{"type":"button"}},[_vm._v("刪除")]),_c('button',{staticClass:"btn btn-main btn-sm me-1 mb-1 mb-md-0",staticStyle:{"white-space":"nowrap"},attrs:{"type":"button"}},[_vm._v("修改")])]):(props.column.field == 'ena')?_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(props.row.ena),expression:"props.row.ena"}],attrs:{"type":"checkbox","name":"ena","id":"ena"},domProps:{"checked":Array.isArray(props.row.ena)?_vm._i(props.row.ena,null)>-1:(props.row.ena)},on:{"change":function($event){var $$a=props.row.ena,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(props.row, "ena", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(props.row, "ena", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(props.row, "ena", $$c)}}}})]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('Meta',{attrs:{"title":_vm.metaTitle}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }