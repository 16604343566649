<template>
  <div class="">
    <div class="text-end">
      <button type="button" class="btn btn-secondary mb-3" @click.prevent="goToCreate">新增分類</button>
    </div>
    <div class="my-5">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        @on-cell-click="onCellClick"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 10,
          perPageDropdownEnabled: true,
          perPageDropdown: [10, 20],
          nextLabel: '下一頁',
          prevLabel: '上一頁',
          rowsPerPageLabel: 'Rows per page',
          allLabel: '全部',
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'btn'">
            <button type="button" class="btn btn-danger btn-sm me-1 mb-1 mb-md-0" style="white-space:nowrap;">刪除</button>
            <button type="button" class="btn btn-main btn-sm me-1 mb-1 mb-md-0" style="white-space:nowrap;">修改</button>
            <!-- <p>{{ props.row.id }}</p> -->
          </span>
          <span v-else-if="props.column.field == 'ena'">
            <input type="checkbox" name="ena" id="ena" v-model="props.row.ena">
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </vue-good-table>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
/* global $ */
import { mapActions, mapGetters } from 'vuex';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

const Swal = require('sweetalert2');

export default {
  name: 'Admin__Categories',
  components: {
    VueGoodTable,
  },
  data() {
    return {
      page: 1,
      data: [],
      noData: false,
      tempCourse: {},
      is_enabled: true,
      createModalRole: '系統管理員',
      columns: [
        {
          label: '啟用',
          field: 'ena',
        },
        {
          label: '類別名稱',
          field: 'name',
        },
        {
          label: '動作',
          field: 'btn',
        },
      ],
      rows: [
      ],
      metaTitle: '課程類別列表 / 分類 / 管理 - 元學堂',
    };
  },
  metaInfo: {
    title: "課程類別列表 / 分類 / 管理 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '課程類別列表 / 分類 / 管理 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    getData() {
      const vm = this;
      this.updateLoading(true);
      this.$http.get(`${this.$API_PATH}/Admin/Course/Category/Management`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.$log.debug(res.data.data.courseCategories);
        this.updateLoading(false);
        this.rows = [...res.data.data.courseCategories];
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '無權限取得列表',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '',
            '錯誤，請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/');
            }
          });
        }
      });
    },
    onCellClick(param) {
      const vm = this;
      if (param.column.field == 'ena') {
        const query = {
          enable: !param.row.ena,
        };
        this.$http.put(`${this.$API_PATH}/Admin/Course/Category/Management/Enable/${param.row.id}`, query, {
          headers: {
            Accept: '*/*',
            Authorization: `bearer ${this.userAuth}`,
            'Content-Type': 'application/json',
          },
        }).then((res) => {
          // console.log(res.data);
          this.getData();
        }).catch((err) => {
          if (err.response) {
            if (err.response.status == 401 || err.response.status == 403) {
              Swal.fire(
                '',
                '無權限取得列表',
                'info',
              ).then((result) => {
                if (result.isConfirmed) {
                  vm.$router.push('/');
                }
              });
            } else if (err.response.status == 404) {
              Swal.fire(
                '',
                '找不到該課程分類',
                'info',
              ).then((result) => {
                if (result.isConfirmed) {
                }
              });
            }
          } else {
            Swal.fire(
              '',
              '錯誤，請稍候再試',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          }
        });
      } else if (param.column.field == 'btn') {
        if (param.event.target.innerText == '刪除') {
          Swal.fire({
            title: '確認刪除？',
            showCancelButton: true,
            confirmButtonText: '確認',
            cancelButtonText: '取消',
          }).then((result) => {
            if (result.isConfirmed) {
              vm.$http.delete(`${this.$API_PATH}/Admin/Course/Category/Management/${param.row.id}`, {
                headers: {
                  Accept: '*/*',
                  Authorization: `bearer ${vm.userAuth}`,
                },
              }).then((res) => {
                // console.log(res.data);
                vm.getData();
              }).catch((err) => {
                if (err.response) {
                  if (err.response.status == 401 || err.response.status == 403) {
                    Swal.fire(
                      '',
                      '無權限取得列表',
                      'info',
                    ).then((result) => {
                      if (result.isConfirmed) {
                        vm.$router.push('/');
                      }
                    });
                  } else if (err.response.status == 404) {
                    Swal.fire(
                      '',
                      `${err.response.data.message}`,
                      'info',
                    );
                  } else if (err.response.status == 409) {
                    Swal.fire(
                      '',
                      `${err.response.data.message}`,
                      'info',
                    );
                  }
                } else {
                  Swal.fire(
                    '',
                    '錯誤，請稍候再試',
                    'info',
                  ).then((result) => {
                    if (result.isConfirmed) {
                      vm.$router.push('/');
                    }
                  });
                }
              });
            }
          });
        } else if (param.event.target.innerText == '修改') {
          Swal.fire({
            title: '輸入類別名稱',
            input: 'text',
            inputValue: param.row.name,
            confirmButtonText: '修改',
            cancelButtonText: '取消',
            inputAttributes: {
              autocapitalize: 'off',
            },
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: (login) => vm.$http.put(`${this.$API_PATH}/Admin/Course/Category/Management/${param.row.id}`,
              {
                name: login,
              }, {
                headers: {
                  Accept: '*/*',
                  Authorization: `bearer ${vm.userAuth}`,
                  'Content-Type': 'application/json',
                },
              }),
          }).then((result) => {
            // console.log(result);
            if (result.isConfirmed) {
              if (result.value.status == 200) {
                Swal.fire(
                  '',
                  `${result.value.data.message}`,
                  'success',
                );
              } else {
                Swal.fire(
                  '',
                  '錯誤',
                  'info',
                );
              }
            }
          });
        }
      }
    },
    goToCreate() {
      const vm = this;
      Swal.fire({
        title: '輸入類別名稱',
        input: 'text',
        inputValue: '',
        confirmButtonText: '新增',
        cancelButtonText: '取消',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: (login) => vm.$http.post(`${this.$API_PATH}/Admin/Course/Category/Management`,
          {
            name: login,
          }, {
            headers: {
              Accept: '*/*',
              Authorization: `bearer ${vm.userAuth}`,
              'Content-Type': 'application/json',
            },
          }),
      }).then((result) => {
        // console.log(result);
        if (result.isConfirmed) {
          if (result.value.status == 200) {
            Swal.fire(
              '',
              `${result.value.data.message}`,
              'success',
            );
            vm.getData();
          } else {
            Swal.fire(
              '',
              '錯誤',
              'info',
            );
          }
        }
      });
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  created() {
    this.cid = this.$route.params.id;
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
  .pageNum {
    cursor: pointer;
    text-decoration: none;
    &.disabled {
      pointer-events: none;
      color: gray;
      cursor: default;
    }
  }
  ::v-deep #vgt-table tbody tr td:nth-of-type(1),
  ::v-deep #vgt-table tbody tr td:nth-of-type(3)
  {
    text-align: center;
  }

  ::v-deep #vgt-table thead tr th:first-child {
    width: 80px !important;
  }

  ::v-deep #vgt-table thead tr th:last-child {
    width: 200px !important;
  }

</style>
